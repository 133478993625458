
























































import { Vue, Component } from "vue-property-decorator";
import Breadcrumb from "@/components/home/Breadcrumb.vue";
@Component({
    components: {
        "al-breadcrumb": Breadcrumb
    }
})
export default class Project_2020 extends Vue {
    earlyProjectData: any = {};
    projectData: any = {
        name: "暂无数据",
        country: {
            cn: "暂无数据",
            en: "No data available"
        },
        filed: {
            cn: ["暂无数据"],
            en: ["No data available"]
        },
        jianjie: "<p style='min-height:250px'>暂无数据</p>",
        jianjiejishu: "<p style='min-height:250px'>暂无数据</p>"
    };

    loading1: boolean = true;
    getEarlyProjectData() {
        // console.log(this.earlyProjectData)
        this.earlyProjectData = this.$route.params;
    }
    getProjectData() {
        const getopt = this.$api.apiList.project_2020;
        const opt = getopt(this.earlyProjectData.pid);

        this.$axios({
            method: opt.method,
            url: opt.url
        }).then((res: any) => {
            console.log(res.data);
            this.projectData = res.data;
            this.loading1 = false;
        });
    }

    mounted() {
        this.loading1 = true;
        this.getEarlyProjectData();
        this.getProjectData();
    }
}
