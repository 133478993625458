
































































import { Vue, Component } from "vue-property-decorator";
import Breadcrumb from "@/components/home/Breadcrumb.vue";
@Component({
    components: {
        "al-breadcrumb": Breadcrumb
    }
})
export default class Expert_2020 extends Vue {
    earlyExpertData: any = {};
    expertData: any = {
        name: "暂无数据",
        country: {
            cn: "暂无数据",
            en: "No data available"
        },
        field: {
            cn: ["暂无数据"],
            en: ["No data available"]
        },
        headimg: false,
        danwei: "暂无数据",
        zhiwei: "暂无数据",
        jianli: "<p style='min-height:250px'>暂无数据</p>"
    };

    loading1: boolean = true;
    getEarlyExpertData() {
        // console.log(this.earlyProjectData)
        this.earlyExpertData = this.$route.params;
    }
    getProjectData() {
        const getopt = this.$api.apiList.expert_2020;
        const opt = getopt(this.earlyExpertData.eid);

        this.$axios({
            method: opt.method,
            url: opt.url
        }).then((res: any) => {
            console.log(res.data);
            this.expertData = res.data;
            this.loading1 = false;
        });
    }

    mounted() {
        this.loading1 = true;
        this.getEarlyExpertData();
        this.getProjectData();
    }
}
