














import { Vue, Component, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
@Component({})
export default class Breadcrumb extends Vue {
    @Getter("getLang")
    lang: any;

    data: any = {
        breadcrumbData: [
            {
                routeName: "home",
                routeContent: this.lang == "en" ? "Home" : "首页"
            }
        ]
    };

    @Watch("$route")
    routeChange(to: any, from: any) {
        this.setBreadcrumb(to);
    }

    @Watch("lang")
    langChange(nval: any, oval: any) {
        this.data.breadcrumbData = [
            {
                routeName: "home",
                routeContent: this.lang == "en" ? "Home" : "首页"
            }
        ];
        this.setBreadcrumb(this.$route);
    }

    setBreadcrumb(route: any) {
        let arry: any;
        if (route.meta.faterRoute) {
            // @ts-ignore
            this.$router.options.routes.some((item: any) => {
                if (item.name == "container_home") {
                    item.children.some((_item: any) => {
                        if (_item.name == route.meta.faterRoute) {
                            arry = [
                                {
                                    routeName: _item.name,
                                    routeContent:
                                        this.$i18n.locale == "en"
                                            ? _item.meta.content_en
                                            : _item.meta.content
                                },
                                {
                                    routeName: route.name,
                                    routeContent:
                                        this.$i18n.locale == "en"
                                            ? route.meta.content_en
                                            : route.meta.content
                                }
                            ];
                        }
                    });
                }
            });
            this.data.breadcrumbData = this.data.breadcrumbData.concat(arry);
        } else {
            arry = [
                {
                    routeName: route.name,
                    routeContent:
                        this.$i18n.locale == "en"
                            ? route.meta.content_en
                            : route.meta.content
                }
            ];
            this.data.breadcrumbData = this.data.breadcrumbData.concat(arry);
        }
        // console.log(this.data.breadcrumbData);
    }

    created() {}
    mounted() {
        this.data.breadcrumbData = [
            {
                routeName: "home",
                routeContent: this.lang == "en" ? "Home" : "首页"
            }
        ];
        // console.log(this.data.breadcrumbData);
        this.setBreadcrumb(this.$route);
    }
    activated() {
        this.data.breadcrumbData = [
            {
                routeName: "home",
                routeContent: this.lang == "en" ? "Home" : "首页"
            }
        ];
        // console.log(this.data.breadcrumbData);
        this.setBreadcrumb(this.$route);
        // console.log(this.$router.options.routes)
    }
}
